exports.components = {
  "component---src-pages-memorize-js": () => import("./../../../src/pages/memorize.js" /* webpackChunkName: "component---src-pages-memorize-js" */),
  "component---src-pages-not-a-doc-js": () => import("./../../../src/pages/not-a-doc.js" /* webpackChunkName: "component---src-pages-not-a-doc-js" */),
  "component---src-pages-pages-js": () => import("./../../../src/pages/pages.js" /* webpackChunkName: "component---src-pages-pages-js" */),
  "component---src-pages-typography-js": () => import("./../../../src/pages/typography.js" /* webpackChunkName: "component---src-pages-typography-js" */),
  "component---src-pages-vim-js": () => import("./../../../src/pages/vim.js" /* webpackChunkName: "component---src-pages-vim-js" */),
  "component---src-pages-work-cycle-clock-js": () => import("./../../../src/pages/work-cycle-clock.js" /* webpackChunkName: "component---src-pages-work-cycle-clock-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../../../src/templates/blog-list-template.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

